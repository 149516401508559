
import {
  BaseTexture,
  Texture,
} from 'pixi.js';

import {
  AtlasAttachmentLoader,
  SkeletonJson,
} from "@pixi-spine/runtime-4.1";

import {
  Spine,
  TextureAtlas,
} from 'pixi-spine';

import {
  CHARACTER_SIZE,
} from './constants';

import {
  Player,
} from './pigeonData';

export function loadSpineData(spineClass) {
  const spineAtlas = new TextureAtlas(spineClass.atlas, function(imageName, callback) {
    // pass the image here.
    const url = spineClass.assets[imageName];
    if (!url) throw new Error(`Image ${imageName} not loaded using require`);

    callback(BaseTexture.from(url));
  });

  const spineAtlasLoader = new AtlasAttachmentLoader(spineAtlas)
  const spineJsonParser = new SkeletonJson(spineAtlasLoader);  

  const maxSize = Math.max(spineClass.json.skeleton.width, spineClass.json.skeleton.height);
  spineJsonParser.scale = CHARACTER_SIZE / maxSize;

  const spineData = spineJsonParser.readSkeletonData(spineClass.json);
  return spineData;
};

export function convertStyleTL(stageData, style) {
  if (!style) return {};

  if (typeof style.bottom !== 'undefined') {
    style.top = stageData.height - style.bottom - (style.height / 2 || 0);
    delete style.bottom;
  }

  if (typeof style.right !== 'undefined') {
    style.left = stageData.width - style.right - (style.width / 2 || 0);
    delete style.right;
  }

  return style;
};

export function distance(rect1, rect2) {
  // Center of rect1
  const center1 = {
    x: rect1.x + rect1.width / 2,
    y: rect1.y + rect1.height / 2,
  };

  const center2 = {
    x: rect2.x + rect2.width / 2,
    y: rect2.y + rect2.height / 2,
  };

  return Math.abs(center1.x - center2.x) + Math.abs(center1.y - center2.y);
};

export function distanceX(rect1, rect2) {
  // Center of rect1
  const center1 = {
    x: rect1.x + rect1.width / 2,
  };

  const center2 = {
    x: rect2.x + rect2.width / 2,
  };

  return Math.abs(center1.x - center2.x);
};

export function rectsCollide(rect1, rect2) {
  return (rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.height + rect1.y > rect2.y);
};

export function entitiesCollide(entity1, entity2, entity1_offset) {
  if (!entity1_offset) {
    entity1_offset = {};
    entity1_offset.x = -entity1.width / 2;
    entity1_offset.y = -entity1.height / 2;
  }

  const rect1 = {
    x: entity1.position.x + entity1_offset.x,
    y: entity1.position.y + entity1_offset.y,
    h: entity1.height,
    w: entity1.width,
  };

  const rect2 = {
    x: entity2.position.x - entity2.width / 2,
    y: entity2.position.y - entity2.height / 2,
    h: entity2.height,
    w: entity2.width,
  };  

  const yHit = (
    rect1.y < rect2.y + rect2.h &&
    rect1.h + rect1.y > rect2.y
  );

  if (!yHit) return '';

  const leftHit = (
    rect1.x + rect1.w > rect2.x &&
    rect1.x + rect1.w < rect2.x + rect2.w
  );

  if (leftHit) return 'left';

  const rightHit = (
    rect1.x < rect2.x + rect2.w &&
    rect1.x > rect2.x
  );

  if (rightHit) return 'right';

  return (
    rect1.x < rect2.x + rect2.w &&
    rect1.x + rect1.w > rect2.x
  ) ? 'in' : '';
};

export function applyAnchor(entity, anchor) {
  if (anchor) {
    // custom
    entity.anchor.set(anchor[0], anchor[1]);
  } else {
    // left-bottom
    entity.anchor.set(0, 1);
  }  
}

export function applyPivot(entity, pivot) {
  if (pivot) {
    // custom
    entity.pivot.set(pivot[0], pivot[1]);
  } else {
    // left-bottom
    entity.pivot.set(0, 1);
  }  
}

export function onSpriteLoad(sprite, callback) {
  if (sprite.texture.baseTexture.valid !== true) {
    sprite.texture.baseTexture.on('loaded', callback);
  } else {
    callback();
  }
}

export function resizeSprite(sprite, width, height) {
  if (!width && !height) return;

  if (width && !height) {
    height = width / sprite.texture.width * sprite.texture.height;

  } else if (!width && height) {
    width = height / sprite.texture.height * sprite.texture.width;
  }

  sprite.width = width;
  sprite.height = height;

  var counter = 0;

  // ToDo: Only set callback for animatedsprite
  sprite.onLoop = () => {
    sprite.width = width;
    sprite.height = height;

    if (++counter > 5) {
      sprite.onLoop = null;
    }
  };
};

export function getTopCenter(entity) {
  const anchor = entity.anchor || entity.pivot;
  const bounds = { w: entity.width, h: entity.height };
  const position = { x: entity.position.x, y: entity.position.y };

  return {
    x: position.x - anchor.x * bounds.w + 0.5 * bounds.w,
    y: position.y - anchor.y * bounds.h,
    width: bounds.w,
    height: bounds.h,
  };
}

export function bringToFront(sprite, parent) {
  var sprite = (typeof(sprite) != "undefined") ? sprite.target || sprite : this;
  var parent = parent || sprite.parent || {
    "children": false
  };
  if (parent.children) {
    for (var keyIndex in sprite.parent.children) {
      if (sprite.parent.children[keyIndex] === sprite) {
        sprite.parent.children.splice(keyIndex, 1);
        break;
      }
    }
    parent.children.push(sprite);
  }
};

export function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
};

export function readTextFile(file, callback, onError) {
  var req = new XMLHttpRequest();
  req.overrideMimeType("application/json");
  req.open("GET", file, true);

  req.onerror = (e) => onError && onError(req.responseText || e);

  req.onreadystatechange = function() {
    if (`${req.status}`[0] !== '2') {
      onError();

    } else if (req.readyState === 4 && req.status == "200") {
      callback(req.responseText);
    }
  }

  req.send(null);
};

export function postRequest(uri, data, callback, onError) {
  var req = new XMLHttpRequest();

  req.open("POST", uri);
  req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

  req.onerror = (e) => onError && onError(e);

  req.onreadystatechange = function() {
    if (`${req.status}`[0] !== '2') {
      onError(req.responseText || 'Request failed');

    } else if (req.readyState === 4 && req.status == "200") {
      callback(req.responseText);
    }
  }

  req.send(typeof data === 'string' ? data : JSON.stringify(data));
};

export function dup(inputArray, c = 2) {
  const outputArray = [];

  for (const element of inputArray) {
    for (let i = 0; i < c; ++i) {
      outputArray.push(element);
    }
  }

  return outputArray;
};
