const scale_idle = 3.8; /* used to scale the idle spine */

export const TIMINGS = { // scroll positions (Y)
  PRODUCT_LOGO: 60,
  INTRO_SLIDE_UP: 120,
  START_WALKING: 340,
};

export const SLIDE_UP_SPEED_FACTOR = 3;
export const SLIDE_UP_EASE = 40;

export const CHARACTER_SIZE = 300;

export const MOVE_SPEED = 5;

export const PLAYER_SCALE = 0.17 * scale_idle;
export const PLAYER_AVATAR = require('data-url:../assets/avatars/alex.png');
export const PLAYER_NAME = 'Player (Alex)';

export const PIGEON_SCALE = 0.23 * scale_idle;
export const PIGEON_SCALE_BATTLE = 0.58;
export const APE_SCALE = 0.16;

export const SPARROW_SCALE = 0.3;
