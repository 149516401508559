
import WebFont from 'webfontloader';

import {
  readTextFile,
} from './utils';

function LoaderUI() {
  const container = document.getElementById('loading-container');

  this.update = (percent) => {
    if (percent >= 100) {
      container.style.opacity = 0;
      container.style.visibility = 'hidden';
    }
  };

  this.reset = () => {
    container.style.opacity = 1;
    container.style.visibility = 'auto';
  };
}

function collectStageAssets(stage) {
  const assets = [];

  const createOrAdd = (uri, layer, key = 'uri') => {
    if (!uri) return;
    
    const existing = assets.find(a => a.uri === uri);

    if (existing) {
      existing.stageResourceData.push(layer);
      existing.keys.push(key);

    } else {
      assets.push({
        type: 'json',
        uri: uri,
        keys: [key],
        stageResourceData: [layer],
      });
    }
  };

  for (let layer of stage.layers) {
    const resourceInfo = layer.uri;
    if (typeof resourceInfo !== 'object') continue;
    createOrAdd(resourceInfo.uri, layer);
  }

  for (let dialogKey of Object.keys(stage.dialogs || {})) {
    const dialog = stage.dialogs[dialogKey];
    const resourceInfo = dialog?.avatar?.uri;

    if (typeof resourceInfo !== 'object') continue;
    createOrAdd(resourceInfo.uri, dialog.avatar);
  }

  for (let spineKey of Object.keys(stage.spines || {})) {
    const spine = stage.spines[spineKey];

    {
      // Atlas
      const resourceInfo = spine.atlas;
      createOrAdd(resourceInfo.uri, spine, 'atlas');
    }

    {
      // JSON
      const resourceInfo = spine.json;
      createOrAdd(resourceInfo.uri, spine, 'json');
    }

    {
      // Images
      for (let imageKey of Object.keys(spine.assets)) {
        const resourceInfo = spine.assets[imageKey];
        createOrAdd(resourceInfo.uri, spine.assets, imageKey);
      }
    }
  }

  return assets;
}

function getStageResources(stage) {
  const resources = [];
  resources.push(...collectStageAssets(stage));

  // This code is most likely obsolete. Every image in a stage
  // is bundled as one single layer using 'parcel-transformer-stageloader'.
  // Code will remain here for compatibilty reasons.
  for (let layer of stage.layers) {
    if (typeof layer.uri !== 'string') continue;
    if (layer.uri.indexOf('data:') === 0) continue; // skip inline

    resources.push({
      type: 'image',
      uri: layer.uri,
    });
  }

  for (let dialogKey of Object.keys(stage.dialogs || {})) {
    const dialog = stage.dialogs[dialogKey];
    const uri = dialog?.avatar?.uri;

    if (typeof uri !== 'string') continue;
    if (uri.indexOf('data:') === 0) continue; // skip inline

    resources.push({
      type: 'image',
      uri: uri,
    });
  }

  return resources;
}

function AssetLoader({onProgress, onComplete}) {
  this.resources = [];
  this.onComplete = onComplete;
  this.isLoading = false;
  this.onCompleteCalled = false;

  this.reset = () => {
    this.resources = [];
    this.isLoading = false;
    this.onCompleteCalled = false;
  };

  this.loadStage = (stage) => {
    const resources = getStageResources(stage);
    this.resources.push(resources);
  };

  this.loadImages = (images) => {
    images.forEach(image => {
      this.resources.push({
        type: 'image',
        uri: image,
      })
    });
  };

  this.loadFont = (fontName) => {
    this.resources.push({
      type: 'font',
      uri: fontName,
    });
  };

  this.load = (fun) => {
    this.resources.push({
      type: 'custom',
      uri: fun,
    });
  };

  this.start = () => {
    if (this.isLoading) return;
    this.isLoading = true;

    const uniq = this.resources
      .flat()
      .filter((value, index, self) => self.findIndex(el => el.uri === value.uri) === index);

    onProgress(1);
    this.next(uniq); // start
  };

  this.next = (resources, index = 0) => {
    if (index >= resources.length) {
      this.isLoading = false;
      onProgress(100);

      if (this.onCompleteCalled) return;
      this.onCompleteCalled = true;
      return this.onComplete();
    }

    const onLoad = () => {
      onProgress(100 * (index + 1) / resources.length);
      this.next(resources, index + 1);
    };

    const resource = resources[index];

    switch (resource.type) {
      case 'font': {
        return WebFont.load({
          google: {
            families: [resource.uri],
          },
          active: () => onLoad()
        });
      }

      case 'image': {
        const image = new Image();
        image.onload = () => onLoad();
        image.onerror = () => onLoad();
        image.src = resource.uri;
        return;
      }

      case 'json': { // Base64 encoded JSON data
        return readTextFile(resource.uri, data => {
          const json = JSON.parse(atob(data));

          // Replace files in stage.layers and dialogs#avatar
          for (let assetIndex = 0; assetIndex < resource.stageResourceData.length; ++assetIndex) {
            const asset = resource.stageResourceData[assetIndex];
            const key = resource.keys[assetIndex] || 'uri';

            const resourceInfo = asset[key];

            if (typeof resourceInfo !== 'object') {
              continue;
            }

            // Get KEY of resource info
            const resourceName = resourceInfo.name;

            // Replace uri (resourceInfo object) with actual data
            const data = json[resourceName];

            if (!data) {
              console.warn(`No data found for ${resourceName}`);
              continue;
            }

            if (data.startsWith('data:text/plain;base64,')) {
              asset[key] = atob(data.substring(data.indexOf(',') + 1));

            } else if (data.startsWith('data:application/json;base64,')) {
              asset[key] = JSON.parse(atob(data.substring(data.indexOf(',') + 1)));

            } else {
              // images, etc.
              asset[key] = data;
            }
          }

          // setTimeout(() => onLoad(), 1500);
          onLoad();
        });
      }

      case 'custom': {
        const fun = resource.uri;
        return fun(() => onLoad());
      }

      default: {
        return onLoad();
      }
    }
  };
};


// Init
const loaderUI = new LoaderUI();
const assetLoader = new AssetLoader({
  onProgress: percentage => loaderUI.update(percentage),
});

// Make globally excessible
window.assetLoader = assetLoader;
window.loaderUI = loaderUI;

assetLoader.loadFont('Press Start 2P');
assetLoader.load((done) => {
  import('./app').then((app) => {
    app.initializeApp();
    // Do not call done(), as app.initializeApp() will call loader.reset()
  });
});

assetLoader.onComplete = () => null;
assetLoader.start();
